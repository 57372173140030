export default {
  props: {
    lineItems: Array,
  },
  methods: {
    formatEasyPostPriceWithoutPickup(carrier) {
      let { price } = carrier;
      if (carrier.scac === "USPS") {
        return price;
      }
      if (carrier.scac === "UPSN" && carrier.source === "easy-post") {
        price -= 6;
      }
      if (carrier.scac === "FDEG" && carrier.source === "easy-post") {
        const totalHandlingUnits = this.handlingUnitsCountTotal();
        price -= totalHandlingUnits * 3;
      }
      return price;
    },
    formatEasyPostPriceWithPickup(carrier) {
      const { price } = carrier;
      return price;
    },
    handlingUnitsCountTotal() {
      let totalHandlingUnits = 0;
      if (this.lineItems.length > 0) {
        for (let i = 0; i < this.lineItems.length; i += 1) {
          totalHandlingUnits += this.lineItems[i].handling_unit_count;
        }
        return totalHandlingUnits;
      }
      return 0;
    },
  },
};
